<!-- System: STA
    Purpose: This compoment will display
            sub header and work diary images
-->
<template>
  <div class="col-lg-12 images-wrapper" v-cloak>
    <div v-if="selectedIndexData.length && selectedIndexData[0].length > 0">
      <PaginationBar
        :index="selectedIndex"
        :keyStrokes="selectedIndexData[0][selectedIndex].key_strokes"
        :mouseClicks="selectedIndexData[0][selectedIndex].mouse_clicks"
        :progress="selectedIndexData[0][selectedIndex].progress"
        :time="selectedIndexData[0][selectedIndex].date"
        :totalSize="selectedIndexData[0].length"
        :video="selectedIndexData[0][selectedIndex].video"
        :workedTime="selectedIndexData[0][selectedIndex].worked_time"
        :screenStats="selectedIndexData[0][selectedIndex].screen_stats"
        :totalWorkedTime="totalWorkedTime"
        @pagination="onPagination"
      />

      <v-style>
        .screenshot { border-top-right-radius : 10px; border-top-left-radius:
        10px; max-width: 100%; height:
        {{ selectedIndexData[0][selectedIndex].height }}px; background-image:
        url({{ base_url }}storage/screenshots/thumbnails/{{
          selectedIndexData[0][selectedIndex].screenshot_token
        }}/{{ selectedIndexData[0][selectedIndex].filename }}); } @media
        (max-width: 768px){ .screenshot{ width: 75px; } } @media (max-width:
        511px){ .screenshot{ width: 100px; } }
      </v-style>


      <!-- <v-style>
        .screenshot { border-top-right-radius : 10px; border-top-left-radius:
        10px; max-width: 100%; height:
        {{ selectedIndexData[0][selectedIndex].height }}px; background-position: bottom; background-image:
        url(https://backoffice.stafftimerapp.com/img/no_image.24b57e28.svg); } @media
        (max-width: 768px){ .screenshot{ width: 75px; } } @media (max-width:
        511px){ .screenshot{ width: 100px; } }
      </v-style> -->

      <div
        :key="index"
        class="col-12 px-0 py-0 m-0 backColor"
        v-for="(screenshot, index) in selectedIndexData[0][selectedIndex].data"
      >
        <TimeSideBar
          :keyStrokes="screenshot.key_strokes"
          :mouseClicks="screenshot.mouse_clicks"
          :secondsWorked="screenshot.seconds_worked"
          :time="screenshot.time"
          :workedTime="screenshot.worked_time"
          :lengthOfScreenshot="screenshot.images"
        />
        <div class="img-holder col-lg-12 row work-diary-images px-0 mr-0 ml-0">
          <v-card
            :key="i"
            elevation="10"
            class="col-img rectangle"
            v-for="(image, i) in screenshot.images"
            v-if="show_image(image)"
          >
            <WorkDiarySingleImage
              :image="image"
              :key="image.id"
              :selectedMember="selectedMember"
              @imageDelete="imageDelete"
            />
          </v-card>
        </div>
      </div>
    </div>
    <div class="text-center" v-else>
      <NoActivityFound />
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";

export default {
  name: "work-diary-images",
  components: {
    TimeSideBar: () => import("@/components/workDiary/TimeSideBar"),
    PaginationBar: () => import("@/components/workDiary/PaginationBar"),
    NoActivityFound: () =>
      import("@/components/includes/errors/NoActivityFound"),
    WorkDiarySingleImage: () =>
      import("@/components/workDiary/WorkDiarySingleImage"),
  },
  props: ["screenshots", "selectedMember", "totalWorkedTime"],
  computed: {
    ...mapState("custom", ["diary_project", "diary_task"]),
    ...mapState("auth", ["user"]),
    base_url() {
      return baseURL.API_URL;
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    getData() {
      console.log("loader", this.$store.state.custom.show_loader);

      return this.$store.state.custom.search_image;
    },
    getDataLoader() {},
  },
  data() {
    return {
      selectedIndex: 0,
      someVal: "",
      selectedIndexData: [],
    };
  },
  beforeUpdate() {
    this.someVal = "someVal";
  },
  watch: {
    selectedIndex: function (val) {
      this.$root.$emit("date-changed", this.selectedIndexData[0][val].date);
    },
    screenshots: {
      handler: function () {
        this.selectedIndexData = JSON.parse(JSON.stringify(this.screenshots));
        if (this.selectedIndexData.length)
          this.$root.$emit(
            "date-changed",
            this.selectedIndexData[0][this.selectedIndex].date
          );
      },
      deep: true,
    },
    diary_task: function () {
      this.filterScreenShots();
    },
    diary_project: function () {
      this.filterScreenShots();
    },
  },
  mounted() {
    this.$root.$on("reset_time", () => {
      this.selectedIndex = 0;
    });
  },

  methods: {
    filterScreenShots() {
      this.selectedIndexData[0][this.selectedIndex].data = this.screenshots[0][
        this.selectedIndex
      ].data.filter((data) =>
        Object.values(data.images).some((image) => this.show_image(image))
      );
    },
    imageDelete(val) {
      this.someVal = val;
      this.$root.$emit("data-clicked", val);
    },

    onPagination(value) {
      this.selectedIndex = value;
    },
    show_image(image) {
      if (!image.filename) {
        return false;
      }
      if (this.diary_project && this.diary_project !== image.project) {
        return false;
      }
      return !(this.diary_task && this.diary_task !== image.task);
    },
  },
};
</script>

<style scoped>
.rectangle {
  height: 110px;
  width: 10%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 25px 35px -20px rgba(172, 184, 207, 0.34);
}
.img-holder .col-img {
  width: 100px;
  margin-bottom: 10px;
  margin-top: 6px;
  margin-right: 13px;
  margin-left: 12px;
}

@media (max-width: 2560px) {
  .img-holder .col-img {
    width: 100px;
  }
}

@media (max-width: 1700px) {
  .img-holder .col-img {
    width: 100px;
  }
}

@media (max-width: 1440px) {
  .img-holder .col-img {
    width: 100px;
  }
}

@media (max-width: 1200px) {
  .img-holder .col-img {
    /*width: 19.6666%;*/
    width: 100px;
  }
}

@media (max-width: 768px) {
  .img-holder .col-img {
    /*width: 30%;*/
    width: 100px;
  }
}
@media (max-width: 630px) {
  .img-holder .col-img {
    width: 100px;
  }
}
@media (max-width: 511px) {
  .img-holder .col-img {
    /*width: 100px;*/
    width: 84px;
  }
}
</style>
